



































































































































import Vue from 'vue';

import { mapState, mapActions } from 'vuex';
import { ComposedState } from '@/store/general.types';
import { purchasesActions } from './store/names';
import { IPaymentSort } from './types';

interface ISortOptions {
    itemsPerPage: number;
    page: number;
    sortBy: string[];
    sortDesc: boolean[];
};

export default Vue.extend({
    name: 'PurchasesPage',

    data() {
        return {
            loading: true,
            options: {} as ISortOptions,
            headers: [
                { text: 'Amount', value: 'amount' },
                { text: 'Country', value: 'country' },
                { text: 'Id', sortable: false, value: 'id' },
                { text: 'Created at', value: 'created_at' },
                { text: 'Payment system', value: 'payment_system' },
                { text: 'Status', value: 'status' },
                { text: 'Tariff', value: 'tariff' },
                { text: 'User', value: 'user' },
                { text: 'Download', sortable: false, value: 'download' },
            ],
            amountFromMin: 1,
            itemsFilter: {
                paymentSystem: ['mypos', 'yookassa', 'paypal', 'paysera'],
                status: ['PAID', 'NOT PAID', 'FAILED'],
            },
            selectFilter: {
                paymentSystem: null as null | string,
                status: null as null | string,
                amountTo: null as null | string,
                amountFrom: null as null | string,
                dateTo: null as null | string,
                dateFrom: null as null | string,
            },
        };
    },

    computed: {
        ...mapState<ComposedState>({
            purchasesData: (state: ComposedState) => state.purchases.purchasesData,
            token: (state: ComposedState) => state.login.access,
        }),
    },

    watch: {
        options: {
            handler() {
                this.querySort().then(() => {
                    this.loading = false;
                });
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions({
            getPaymentsApi: purchasesActions.getPaymentsApi,
        }),

        async querySort() {
            this.loading = true;

            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            const sorting = {};

            if (sortBy.length) {
                sortBy.forEach((item, index) => {
                    sorting[item] = sortDesc[index] ? 'desc' : 'asc';
                });
            }

            const query: IPaymentSort = {
                size: itemsPerPage,
                page,
                sort_by: Object.keys(sorting)[0],
                sort: Object.values(sorting)[0],
            };

            if (this.selectFilter.paymentSystem) {
                query.payment_system = this.selectFilter.paymentSystem;
            }

            if (this.selectFilter.status) {
                query.status = this.selectFilter.status;
            }

            if (this.selectFilter.amountTo) {
                query.amount_gte = this.selectFilter.amountTo;
                this.amountFromMin = Number(this.selectFilter.amountTo) + 1;
            }

            if (this.selectFilter.amountFrom) {
                query.amount_lte = this.selectFilter.amountFrom;
            }

            if (this.selectFilter.dateTo) {
                query.created_gte = this.selectFilter.dateTo;
            }

            if (this.selectFilter.dateFrom) {
                query.created_lte = this.selectFilter.dateFrom;
            }

            await this.getPaymentsApi(query);
            this.loading = false;
        },

        resetFilter() {
            this.selectFilter.paymentSystem = null;
            this.selectFilter.status = null;
            this.selectFilter.amountTo = null;
            this.selectFilter.amountFrom = null;
            this.selectFilter.dateTo = null;
            this.selectFilter.dateFrom = null;
            this.querySort();
        },

        async downloadFile(id: number) {
            const link = `${process.env.VUE_APP_URL_REST}/admin/payment-invoice/${id}`;
            const headers = {

                // @ts-ignore
                Authorization: `JWT ${this.token}`,
            };

            try {
                const response = await fetch(link, { headers });
                const blob = await response.blob();
                const a = document.createElement('a');

                a.href = URL.createObjectURL(blob);
                a.download = `${id}.pdf`;
                a.click();
                a.remove();

                // @ts-ignore
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error on download file:', error);
            }
        },
    },
});
